<template>
  <div>
    <!-- no lists state -->
    <v-alert
      v-if="!hasLists"
      border="top"
      colored-border
      type="info"
      elevation="2"
    >
      <v-row>
        <v-col>
          You do not have access to any email lists.
        </v-col>
      </v-row>
    </v-alert>
    <!--/ no lists state -->
    <!-- user has lists -->
    <v-container
      v-if="hasLists"
      fluid
    >
      <!-- select list -->
      <v-row>
        <v-col>
          <h2>
            Email Lists
          </h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
          xl="4"
        >
          <v-select
            v-model="typeId"
            :items="computedLists"
            item-text="name"
            item-value="typeId"
            label="Select List"
          />
        </v-col>
      </v-row>
      <!--/ select list -->
      <v-tabs>
        <v-tab>Email List</v-tab>
        <v-tab>Unsubscribes</v-tab>
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
          class="ma-1"
        >
          <!-- add single user to list -->
          <ValidationObserver ref="observer">
            <v-form @submit.prevent="addListUser">
              <v-row
                class="mt-2"
                dense
              >
                <v-col
                  cols="12"
                  md="4"
                  xl="3"
                >
                  <ValidationProvider
                    ref="emailValidation"
                    v-slot="{ errors }"
                    rules="email"
                    name="Email"
                  >
                    <v-text-field
                      v-model="email"
                      placeholder="Email"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  xl="3"
                >
                  <v-text-field
                    v-model.trim="type"
                    placeholder="Type"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  xl="3"
                >
                  <v-btn
                    color="primary"
                    type="submit"
                    :disabled="email === ''"
                  >
                    Add to list
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <!--/ add single user to list -->
          <v-row class="mt-4 mb-2">
            <v-col>
              <v-file-input
                ref="emailListFile"
                v-model="emailListFile"
                label="Email List File"
                accept=".csv,.txt"
                class="sr-only"
              />
              <v-btn
                style="border: 1px solid rgba(0, 0, 0, 0.12)"
                depressed
                :loading="emailListFileUploading"
                @click="onClickUploadEmailListFile"
              >
                <v-icon
                  small
                  left
                >
                  fas fa-upload
                </v-icon>
                Upload Email List
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col
              cols="12"
              md="8"
              xl="6"
            >
              <v-data-table
                :headers="listHeaders"
                :items="listUsers"
                :loading="listLoading"
                :search="listSearch"
                :disable-pagination="true"
                hide-default-footer
                fixed-header
                height="50vh"
                class="elevation-5 ma-1 mt-4"
                group-by="type"
                show-group-by
              >
                <template #[`item.email`]="{ item }">
                  <v-btn
                    v-if="item.isCadenceAdded === true"
                    fab
                    text
                    @click="deleteEmail(item)"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                  {{ item.email }}
                </template>
                <template #top>
                  <v-text-field
                    v-model="listSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="pa-3"
                  />
                </template>
                <template #footer>
                  <div class="pa-3">
                    <a
                      class="primary--text"
                      @click.prevent="download"
                      @keypress.enter="download"
                    >
                      Click to download all users in the list
                    </a>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item
          :transition="false"
          :reverse-transition="false"
        >
          <v-row
            dense
            class="mt-2"
          >
            <v-col
              cols="12"
              md="8"
              xl="6"
            >
              <v-data-table
                :headers="unsubscribeHeaders"
                :items="unsubscribeUsers"
                :loading="unsubscribeLoading"
                :search="unsubscribeSearch"
                :disable-pagination="true"
                hide-default-footer
                fixed-header
                height="50vh"
                class="elevation-5 ma-1 mt-4"
              >
                <template #[`item.email`]="{ item }">
                  <v-btn
                    fab
                    text
                    @click="deleteUnsubscribe(item)"
                  >
                    <v-icon>
                      delete
                    </v-icon>
                  </v-btn>
                  {{ item.email }}
                </template>
                <template #[`item.createdAt`]="{ item }">
                  {{ formatCreated(item.createdAt) }}
                </template>
                <template #top>
                  <v-text-field
                    v-model="unsubscribeSearch"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="pa-3"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-container>
    <!--/ user has lists -->
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import emailList from '../_api/emailList';

export default {
  name: 'EmailListManagement',
  data() {
    return {
      lists: [],
      typeId: null,
      email: '',
      type: '',
      emailListFile: null,
      emailListFileUploading: false,
      listHeaders: [
        {
          text: 'Email',
          value: 'email',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
          groupable: true,
        },
        {
          text: 'Is Cadence Added',
          value: 'isCadenceAdded',
          sortable: true,
          groupable: true,
        },
      ],
      listUsers: [],
      listSearch: '',
      listLoading: false,
      unsubscribeHeaders: [
        {
          text: 'Email',
          value: 'email',
          sortable: true,
        },
        {
          text: 'Unsubscribed On',
          value: 'createdAt',
          sortable: true,
        },
      ],
      unsubscribeUsers: [],
      unsubscribeLoading: false,
      unsubscribeSearch: '',
      hasLists: true,
    };
  },
  computed: {
    computedLists() {
      return this.lists.map((l) => ({
        typeId: l.typeId,
        name: l.duplicateName === true ? `${l.name} (${l.vertical})` : l.name,
      }));
    },
  },
  watch: {
    async typeId() {
      await this.getData();
    },
    async emailListFile(file) {
      if (file) {
        await this.uploadEmailListFile();
      }
    },
  },
  async mounted() {
    const resp = await emailList.getLists();
    this.lists = resp.data;

    if (this.lists.length > 0) {
      this.typeId = this.lists[0].typeId;
    } else {
      this.hasLists = false;
    }
    await this.getData();
  },
  methods: {
    async getData() {
      await Promise.all([this.getListData(), this.getUnsubscribeData()]);
    },
    async getListData() {
      this.listLoading = true;
      const resp = await emailList.getListEmails(this.typeId);
      this.listUsers = resp.data;
      this.listLoading = false;
    },
    async getUnsubscribeData() {
      this.unsubscribeLoading = true;
      const resp = await emailList.getListUnsubscribes(this.typeId);
      this.unsubscribeUsers = resp.data;
      this.unsubscribeLoading = false;
    },
    async deleteEmail(item) {
      if (await this.$confirm(`Are you sure you want to delete ${item.email} from the list?`)) {
        await emailList.deleteEmail(this.typeId, item.email);
        await this.getListData();
      }
    },
    async addListUser() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await emailList.addEmail(this.typeId, this.email, this.type);
        this.email = '';
        this.type = '';
        await this.getListData();
      }
    },
    async deleteUnsubscribe(item) {
      if (await this.$confirm(`Are you sure you want to remove the unsubscribe for ${item.email}?`)) {
        await emailList.deleteUnsubscribe(this.typeId, item.email);
        await this.getUnsubscribeData();
      }
    },
    formatCreated(value) {
      return new Date(value).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
    },
    async download() {
      const blob = new Blob(
        ['email,type\r\n', this.listUsers.map((user) => `${user.email},${user.type}`).join('\r\n')],
        { type: 'text/plain;charset=utf-8' },
      );
      saveAs(blob, 'Email List.csv');
    },
    async onClickUploadEmailListFile() {
      if (!this.emailListFile) {
        this.$refs.emailListFile.$el.querySelector('input').click();
      } else {
        await this.uploadEmailListFile();
      }
    },
    async uploadEmailListFile() {
      try {
        this.emailListFileUploading = true;
        await emailList.uploadFile(this.typeId, this.emailListFile);
        this.emailListFile = null;

        this.$nextTick(() => {
          window.setTimeout(() => {
            this.emailListFileUploading = false;
          }, 300);
        });
      } catch {
        // ignore
      }
    },
  },
};
</script>
