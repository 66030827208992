<template>
  <div>
    <v-dialog
      v-model="imports.dialog"
      max-width="500"
    >
      <v-card
        class="mx-auto"
        tile
      >
        <v-card-title>
          Paste in data
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            ref="importDataValidation"
            v-slot="{ errors }"
            name="Data"
          >
            <v-text-field
              v-model="imports.data"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="performImport"
          >
            Perform Import
          </v-btn>
          <v-btn
            class="ml-2"
            @click="imports.dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="exports.dialog"
      max-width="500"
    >
      <v-card
        class="mx-auto"
        tile
      >
        <v-card-title>
          Copy below data
        </v-card-title>
        <v-card-text>
          <copy-box :value="exports.data" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <ValidationObserver ref="observer">
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            class="mt-2"
            @click="performExport"
          >
            Export
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2 ml-2"
            @click="imports.dialog = true"
          >
            Import
          </v-btn>
        </v-col>
      </v-row>
      <v-form
        class="mt-3"
        @submit.prevent="save"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              ref="accountTypeValidation"
              v-slot="{ errors }"
              name="Account Type"
              rules="required"
            >
              <v-select
                v-model="formData.accountType"
                :items="accountTypes"
                :error-messages="errors"
                label="Account Type"
                @change="permissionChange"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              ref="pathValidation"
              v-slot="{ errors }"
              name="Path"
              rules="required|alpha_dash"
            >
              <v-text-field
                v-model="formData.path"
                :error-messages="errors"
                label="Path"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              ref="verticalValidation"
              v-slot="{ errors }"
              name="Vertical"
              rules="required"
            >
              <v-select
                v-model="formData.vertical"
                :error-messages="errors"
                label="Vertical"
                :items="verticals"
                item-text="name"
                item-value="id"
                @change="permissionChange"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              ref="referenceNameValidation"
              v-slot="{ errors }"
              name="Reference Name"
              rules="required"
            >
              <v-text-field
                v-model="formData.referenceName"
                :error-messages="errors"
                label="Reference Name"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Title"
              rules="required"
            >
              <v-text-field
                v-model="formData.title"
                :error-messages="errors"
                label="Title"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-text-field
              v-model="formData.category"
              label="Category"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-text-field
              v-model="formData.description"
              label="Description"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-text-field
              v-model="formData.icon"
              label="Icon"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Report Url"
              rules="url|required"
            >
              <v-text-field
                v-model="formData.reportUrl"
                label="Report Url"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-select
              v-model="formData.permission"
              label="Permission"
              :items="permissions"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-checkbox
              v-model="formData.requireSystemUser"
              label="Require System User"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-checkbox
              v-model="formData.isActive"
              label="Is Active"
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-subheader class="px-0">
              Guide
            </v-subheader>
            <wysiwyg-editor
              v-model="formData.guide"
              style="height: 500px; margin-bottom: 43.86px;"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              type="submit"
              color="primary"
              class="mt-2"
            >
              Save
            </v-btn>
            <v-btn
              class="mt-2 ml-2"
              to="/admin/reportManagement"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validationobserver>
  </div>
</template>

<script>
import { camelCase, lowerCase } from 'lodash';
import verticals from '@/api/verticals';
import WysiwygEditor from '@/components/WysiwygEditor';
import reports from '../_api/reports';
import CopyBox from '../../../components/CopyBox';

export default {
  name: 'AdminReport',
  components: {
    CopyBox,
    WysiwygEditor,
  },
  props: {
    verticalId: {
      type: String,
      required: false,
      default: '',
    },
    accountType: {
      type: String,
      required: false,
      default: '',
    },
    path: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      imports: {
        data: null,
        dialog: false,
      },
      exports: {
        data: null,
        dialog: false,
      },
      overlay: false,
      accountTypes: [
        'admin',
        'brand',
        'other',
        'retailer',
        'retailer rollup',
      ],
      verticals: [],
      permissions: [],
      formData: {
        accountType: null,
        path: null,
        vertical: null,
        referenceName: null,
        title: null,
        description: null,
        icon: null,
        reportUrl: null,
        permission: null,
        requireSystemUser: false,
        isActive: false,
        category: null,
        guide: null,
      },
    };
  },
  async created() {
    this.overlay = true;
    const resp = await verticals.getVerticals(true);
    this.verticals = resp.data;
    if (this.verticalId) {
      const report = await reports.getReport(this.verticalId, this.accountType, this.path);
      this.loadData(report.data);
    }
    this.overlay = false;
  },
  methods: {
    loadData(data) {
      this.formData.vertical = parseInt(data.vertical || this.verticalId, 10);
      this.formData.accountType = data.accountType || lowerCase(this.accountType);
      this.formData.path = data.path || this.path;
      this.formData.referenceName = data.referenceName;
      this.formData.title = data.title;
      this.formData.description = data.description;
      this.formData.icon = data.icon;
      this.formData.reportUrl = data.reportUrl;
      this.formData.permission = data.permission;
      this.formData.requireSystemUser = data.requireSystemUser;
      this.formData.isActive = data.isActive;
      this.formData.category = data.category;
      this.formData.guide = data.guide;
      this.setPermissionList();
    },
    permissionChange() {
      this.formData.permission = null;
      this.setPermissionList();
    },
    setPermissionList() {
      if (this.formData.accountType && this.formData.vertical) {
        this.verticals.every((v) => {
          if (v.id === this.formData.vertical) {
            this.permissions = v.metadata[`${camelCase(this.formData.accountType)}ReportPermissions`] || [];
            return false;
          }
          return true;
        });
      } else {
        this.permissions = [];
        this.formData.permission = null;
      }
    },
    async performExport() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.exports.data = JSON.stringify(this.formData);
        this.exports.dialog = true;
      }
    },
    performImport() {
      if (this.imports.data) {
        try {
          const data = JSON.parse(this.imports.data);
          this.loadData(data);
          this.imports.dialog = false;
        } catch (e) {
          this.$refs.importDataValidation.setErrors(['Invalid JSON']);
        }
      }
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      const {
        accountType, path, title, requireSystemUser, permission, reportUrl, vertical, description,
        icon, referenceName, isActive, category, guide,
      } = this.formData;
      if (isValid) {
        try {
          if (this.verticalId) {
            await reports.updateReport(
              this.verticalId,
              this.accountType,
              this.path,
              accountType,
              path,
              title,
              requireSystemUser,
              permission,
              reportUrl,
              vertical,
              description,
              icon,
              referenceName,
              isActive,
              category,
              guide,
            );
          } else {
            await reports.createReport(
              accountType,
              path,
              title,
              requireSystemUser,
              permission,
              reportUrl,
              vertical,
              description,
              icon,
              referenceName,
              isActive,
              category,
              guide,
            );
          }
          this.$router.push('/admin/reportManagement/');
        } catch (e) {
          if (e.response.data === 'DuplicateReferenceName') {
            this.$refs.referenceNameValidation.setErrors(['Reference name must be unique per vertical.']);
          } else if (e.response.data === 'NotUnique') {
            this.$refs.accountTypeValidation.setErrors(['The combination of Account Type, Path, and Vertical must be unique.']);
            this.$refs.pathValidation.setErrors(['The combination of Account Type, Path, and Vertical must be unique.']);
            this.$refs.verticalValidation.setErrors(['The combination of Account Type, Path, and Vertical must be unique.']);
          }
          // handle duplicate errors
        }
      }
    },
  },
};
</script>
