<template>
  <div class="sigma-report-container">
    <iframe
      ref="iframe"
      :title="report.title || 'Dashboard'"
      :src="report.url"
      frameBorder="0"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>
export default {
  name: 'SigmaReport',
  props: {
    report: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    window.addEventListener('message', this.onMessage);
  },
  destroyed() {
    window.removeEventListener('message', this.onMessage);
  },
  methods: {
    onMessage(event) {
      if (
        event.source === this.$refs.iframe.contentWindow
        && event.origin === 'https://app.sigmacomputing.com'
      ) {
        this.$emit(event.data.type.replaceAll(':', '-'), event);
      }
    },
  },
};
</script>

<style scoped>
  .sigma-report-container {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
  }
</style>
