import reset from '@/store/utils/reset';
import dashboard from '../../_api/dashboard';
import workbook from './modules/workbook';

function initialState() {
  return {
    help: false,
    guide: false,
    expanded: false,
    report: {
      id: null,
      title: null,
      url: null,
      guide: null,
    },
  };
}

// ---
export default {
  namespaced: true,
  state: initialState(),
  modules: {
    workbook,
  },
  mutations: {
    SET_REPORT(state, report) {
      state.report = { ...state.report, ...report };
    },
    SET_HELP(state, help) {
      state.help = help;
    },
    SET_GUIDE(state, guide) {
      state.guide = guide;
    },
    RESET(state) {
      reset(state, initialState());
    },
    SET_EXPANDED(state, expanded) {
      state.expanded = expanded;
    },
  },
  actions: {
    async init(context, { accountType, reportSlug }) {
      const {
        rootState, dispatch, commit,
      } = context;

      const { activeAccount: { id: accountId } } = rootState.user;

      const [{ data }, hasViewedReportGuide] = await Promise.all([
        dashboard.getDashboard(
          accountType.toLowerCase(),
          accountId,
          reportSlug,
        ),
        dispatch('settings/get', 'hasViewedReportGuide', { root: true }),
      ]);

      commit('SET_GUIDE', !hasViewedReportGuide);
      commit('SET_REPORT', data);
    },
    setHelp({ commit }, help) {
      commit('SET_HELP', help);
    },
    setGuide({ commit }, guide) {
      commit('SET_GUIDE', guide);
    },
    reset({ commit, dispatch }) {
      commit('RESET');
      dispatch('workbook/reset');
    },
    setExpanded({ commit }, expanded) {
      commit('SET_EXPANDED', expanded);
    },
    logout: {
      root: true,
      handler({ dispatch }) {
        dispatch('reset');
      },
    },
  },
};
