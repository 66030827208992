import axios from '@/utils/axios';
import { memoize } from 'lodash';

/**
 * Fetches the reports, memoizing the promise based on the params provided.
 *
 * @type {function}
 * @param {{ verticalId?: Number }} params
 */
const getReports = memoize(
  (params = {}) => axios.get('/admin/reports', { params }),
  (params) => JSON.stringify(params),
);

const oldGetReports = (params = {}) => axios.get('/admin/reports', { params });

/**
 * Fetches a specific report by it's id.
 *
 * @type {function}
 * @param {string} id
 */
const getReportById = memoize((id) => axios.get(`/admin/reports/${id}`));

/**
 * Updates the report by it's id.
 *
 * @type {function}
 * @param {string} id
 * @param {{*}} payload
 */
const patchReport = (id, payload) => axios.patch(`admin/reports/${id}`, payload);

const getReport = (verticalId, accountType, path) => axios.get(`/admin/reports/${verticalId}/${accountType}/${path}`);
const createReport = (accountType, path, title, requireSystemUser, permission, reportUrl, verticalId, description, icon, referenceName, isActive, category, guide) => axios.post('/admin/reports', {
  accountType,
  path,
  title,
  requireSystemUser,
  permission,
  reportUrl,
  verticalId,
  description,
  icon,
  referenceName,
  isActive,
  category,
  guide,
});
const updateReport = (verticalParam, accountTypeParam, pathParam, accountType, path, title, requireSystemUser, permission, reportUrl, verticalId, description, icon, referenceName, isActive, category, guide) => axios.put(`/admin/reports/${verticalParam}/${accountTypeParam}/${pathParam}`, {
  accountType,
  path,
  title,
  requireSystemUser,
  permission,
  reportUrl,
  verticalId,
  description,
  icon,
  referenceName,
  isActive,
  category,
  guide,
});
const deleteReport = (verticalId, accountType, path) => axios.delete(`/admin/reports/${verticalId}/${accountType}/${path}`);

export default {
  getReports,
  oldGetReports,
  getReportById,
  patchReport,
  getReport,
  createReport,
  updateReport,
  deleteReport,
};
