/**
 * The workbook is the active representation of what a user is viewing
 * as the sigma report.
 */
export default {
  namespaced: true,
  state: {
    loaded: false,
  },
  mutations: {
    SET_LOADED(state, loaded) {
      state.loaded = loaded;
    },
    RESET(state) {
      state.loaded = false;
    },
  },
  actions: {
    init({ commit }) {
      commit('SET_LOADED', true);
    },
    reset({ commit }) {
      commit('RESET');
    },
  },
};
