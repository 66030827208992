import axios from '@/utils/axios';

const getLists = () => axios.get('/admin/emailList');
const getListEmails = (typeId) => axios.get(`/admin/emailList/typeId/${typeId}/emails`);
const getListUnsubscribes = (typeId) => axios.get(`/admin/emailList/typeId/${typeId}/unsubscribes`);

const addEmail = (typeId, email, type) => axios.post(`/admin/emailList/typeId/${typeId}`, {
  email,
  type,
});

const deleteEmail = (typeId, email) => axios.delete(`/admin/emailList/typeId/${typeId}`, {
  data: {
    email,
  },
});

const deleteUnsubscribe = (typeId, email) => axios.delete(`/admin/emailList/typeId/${typeId}/unsubscribe`, {
  data: {
    email,
  },
});

const uploadFile = (typeId, file) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return axios.post(`/admin/emailList/typeId/${typeId}/files`, formData);
};

export default {
  getLists,
  getListEmails,
  getListUnsubscribes,
  addEmail,
  deleteEmail,
  deleteUnsubscribe,
  uploadFile,
};
